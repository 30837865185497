.react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: relative;
  width: 100%;
}

.react-tel-input :disabled {
  cursor: not-allowed;
}

.react-tel-input .flag {
  width: 22px;
  height: 16px;
  background-repeat: no-repeat;
}
.react-tel-input .nl {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M0 114h513v114H0z'/%3e%3cpath fill='%23cd1f2a' d='M0 0h513v114H0z'/%3e%3cpath fill='%231d4185' d='M0 228h513v114H0z'/%3e%3c/svg%3e");
}
.react-tel-input .be {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='%23fdda25' d='M0 0h513v342H0z'/%3e%3cpath d='M0 0h171v342H0z'/%3e%3cpath fill='%23ef3340' d='M342 0h171v342H342z'/%3e%3c/svg%3e");
}
.react-tel-input .cz {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='%2311457e' d='M0 0h513v342H0z'/%3e%3cpath fill='%23d7141a' d='M513 171v171H0l215-171z'/%3e%3cpath fill='white' d='M513 0v171H215.185L0 0z'/%3e%3c/svg%3e");
}
.react-tel-input .de {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='%23D80027' d='M0 0h513v342H0z'/%3e%3cpath d='M0 0h513v114H0z'/%3e%3cpath fill='%23FFDA44' d='M0 228h513v114H0z'/%3e%3c/svg%3e");
}
.react-tel-input .dk {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='%23c60c30' d='M0 0h513v342H0z'/%3e%3cpath fill='white' d='M190 0h-60v140H0v60h130v142h60V200h323v-60H190z'/%3e%3c/svg%3e");
}
.react-tel-input .fr {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M0 0h513v342H0z'/%3e%3cpath fill='%230052B4' d='M0 0h171v342H0z'/%3e%3cpath fill='%23D80027' d='M342 0h171v342H342z'/%3e%3c/svg%3e");
}
.react-tel-input .at {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M0 114h513v114H0z'/%3e%3cpath fill='%23D80027' d='M0 0h513v114H0zm0 228h513v114H0z'/%3e%3c/svg%3e");
}
.react-tel-input .es {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.5 15'%3e%3cpath fill='white' d='M0 0h22.5v15H0V0z'/%3e%3cpath fill='%23D03433' d='M0 0h22.5v4H0V0zm0 11h22.5v4H0v-4z'/%3e%3cpath fill='%23FBCA46' d='M0 4h22.5v7H0V4z'/%3e%3cpath fill='white' d='M7.8 7h1v.5h-1V7z'/%3e%3cpath fill='%23A41517' d='M7.2 8.5c0 .3.3.5.6.5s.6-.2.6-.5L8.5 7H7.1l.1 1.5zM6.6 7c0-.3.2-.5.4-.5h1.5c.3 0 .5.2.5.4V7l-.1 1.5c-.1.6-.5 1-1.1 1-.6 0-1-.4-1.1-1L6.6 7z'/%3e%3cpath fill='%23A41517' d='M6.8 7.5h2V8h-.5l-.5 1-.5-1h-.5v-.5zM5.3 6h1v3.5h-1V6zm4 0h1v3.5h-1V6zm-2.5-.5c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v.2c0 .2-.1.3-.3.3H7c-.1 0-.2-.1-.2-.2v-.3z'/%3e%3c/svg%3e");
}
.react-tel-input .hu {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M0 0h513v342H0z'/%3e%3cpath fill='%23D80027' d='M0 0h513v114H0z'/%3e%3cpath fill='%236DA544' d='M0 228h513v114H0z'/%3e%3c/svg%3e");
}
.react-tel-input .it {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M342 0H0v341.3h512V0z'/%3e%3cpath fill='%236DA544' d='M0 0h171v342H0z'/%3e%3cpath fill='%23D80027' d='M342 0h171v342H342z'/%3e%3c/svg%3e");
}
.react-tel-input .lu {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cpath fill='white' d='M0 0h513v342H0z'/%3e%3cpath fill='%23D80027' d='M0 0h513v114H0z'/%3e%3cpath fill='%23338AF3' d='M0 228h513v114H0z'/%3e%3c/svg%3e");
}
.react-tel-input .mc {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='white' d='M0 85.333h512v341.333H0z'/%3e%3cpath fill='%23c70000' d='M0 85.333h512V256H0z'/%3e%3c/svg%3e");
}
.react-tel-input .pl {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cg fill='white'%3e%3cpath d='M0 85.337h512v341.326H0z'/%3e%3cpath d='M0 85.337h512V256H0z'/%3e%3c/g%3e%3cpath fill='%23D80027' d='M0 256h512v170.663H0z'/%3e%3c/svg%3e");
}
.react-tel-input .pt {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='%23D80027' d='M0 85.337h512v341.326H0z'/%3e%3cpath fill='%236DA544' d='M196.641 85.337v341.326H0V85.337z'/%3e%3ccircle fill='%23FFDA44' cx='196.641' cy='256' r='64'/%3e%3cpath fill='%23D80027' d='M160.638 224v40.001c0 19.882 16.118 36 36 36s36-16.118 36-36V224h-72z'/%3e%3cpath fill='white' d='M196.638 276c-6.617 0-12-5.383-12-12v-16h24.001v16c-.001 6.616-5.385 12-12.001 12z'/%3e%3c/svg%3e");
}
.react-tel-input .ro {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='%23FFDA44' d='M0 85.331h512v341.326H0z'/%3e%3cpath fill='%230052B4' d='M0 85.331h170.663v341.337H0z'/%3e%3cpath fill='%23D80027' d='M341.337 85.331H512v341.337H341.337z'/%3e%3c/svg%3e");
}
.react-tel-input .se {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='%230052B4' d='M0 85.333h512V426.67H0z'/%3e%3cpath fill='%23FFDA44' d='M192 85.33h-64v138.666H0v64h128v138.666h64V287.996h320v-64H192z'/%3e%3c/svg%3e");
}
.react-tel-input .si {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='white' d='M0 85.337h512v341.326H0z'/%3e%3cpath fill='%230052B4' d='M0 199.112h512v113.775H0z'/%3e%3cpath fill='%23D80027' d='M0 312.888h512v113.775H0z'/%3e%3cpath fill='white' d='M233.606 196.639v14.837c0 34.081-44.522 44.522-44.522 44.522s-44.522-10.44-44.522-44.522v-14.837l-.145-44.188 89.043-.266.146 44.454z'/%3e%3cpath fill='%230052B4' d='M233.606 196.639v14.837c0 34.081-44.522 44.522-44.522 44.522s-44.522-10.44-44.522-44.522v-14.837l-.145-44.188 89.043-.266.146 44.454z'/%3e%3cpath fill='white' d='M233.606 196.639v14.837c0 34.081-44.522 44.522-44.522 44.522s-44.522-10.44-44.522-44.522v-14.837l14.848 14.837 29.674-22.261 29.685 22.261 14.837-14.837z'/%3e%3c/svg%3e");
}
.react-tel-input .sk {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='white' d='M0 85.337h512v341.326H0z'/%3e%3cpath fill='%230052B4' d='M0 196.641h512v118.717H0z'/%3e%3cpath fill='%23D80027' d='M0 315.359h512v111.304H0z'/%3e%3cpath fill='white' d='M129.468 181.799v85.136c0 48.429 63.267 63.267 63.267 63.267S256 315.362 256 266.935v-85.136H129.468z'/%3e%3cpath fill='%23D80027' d='M146.126 184.294v81.941c0 5.472 1.215 10.64 3.623 15.485h85.97c2.408-4.844 3.623-10.012 3.623-15.485v-81.941h-93.216z'/%3e%3cpath fill='white' d='M221.301 241.427h-21.425v-14.283h14.284v-14.283h-14.284v-14.284h-14.283v14.284h-14.282v14.283h14.282v14.283h-21.426v14.284h21.426v14.283h14.283v-14.283h21.425z'/%3e%3cpath fill='%230052B4' d='M169.232 301.658c9.204 5.783 18.66 9.143 23.502 10.636 4.842-1.494 14.298-4.852 23.502-10.636 9.282-5.833 15.79-12.506 19.484-19.939a24.878 24.878 0 0 0-14.418-4.583c-1.956 0-3.856.232-5.682.657-3.871-8.796-12.658-14.94-22.884-14.94-10.227 0-19.013 6.144-22.884 14.94a25.048 25.048 0 0 0-5.682-.657 24.88 24.88 0 0 0-14.418 4.583c3.691 7.433 10.198 14.106 19.48 19.939z'/%3e%3c/svg%3e");
}
.react-tel-input .ch {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 513 342'%3e%3cpath fill='red' d='M0 85.337h513v342H0z'/%3e%3cpath fill='white' d='M356.174 222.609h-66.783v-66.783h-66.782v66.783h-66.783v66.782h66.783v66.783h66.782v-66.783h66.783z'/%3e%3c/svg%3e");
}
.react-tel-input .gb {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 513 342'%3e%3cg fill='white'%3e%3cpath d='M0 0h513v341.3H0V0z'/%3e%3cpath d='M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6z'/%3e%3c/g%3e%3cpath d='M393.8 230 513 295.7V230H393.8zm-82.1 0L513 341.3v-31.5L369.3 230h-57.6zm146.9 111.3-147-81.7v81.7h147zM90.3 230 0 280.2V230h90.3zm110 14.2v97.2H25.5l174.8-97.2zm-82.1-132.9L0 45.6v65.7h118.2zm82.1 0L0 0v31.5l143.7 79.8h56.6zM53.4 0l147 81.7V0h-147zm368.3 111.3L513 61.1v50.2h-91.3zm-110-14.2V0h174.9L311.7 97.1z' fill='%230052B4'/%3e%3cg fill='%23D80027'%3e%3cpath d='M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288V0z'/%3e%3cpath d='M311.7 230 513 341.3v-31.5L369.3 230h-57.6zm-168 0L0 309.9v31.5L200.3 230h-56.6zm56.6-118.7L0 0v31.5l143.7 79.8h56.6zm168 0L513 31.5V0L311.7 111.3h56.6z'/%3e%3c/g%3e%3c/svg%3e");
}

.react-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.react-tel-input .hide {
  display: none;
}

.react-tel-input .v-hide {
  visibility: hidden;
}

.react-tel-input .form-control {
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #9ca3af;
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding: 12px 24px 12px 64px;
  color: #282f36;
}

.react-tel-input .form-control:focus {
  border-color: #039FFC;
  outline: 0;
  box-shadow: 0 0 0 2px #9AD9FE;
}

.react-tel-input .form-control:disabled {
  border-color: rgba(156, 163, 175, 0.6);
  background-color: rgba(243, 244, 246, 0.6);
}

.react-tel-input .form-control:focus.invalid-number {
  box-shadow: 0 0 0 2px #FFB6DE;
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #FE49AD;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  cursor: pointer;
}

.react-tel-input .flag-dropdown.open {
  z-index: 2;
}

.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default;
}

.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 4px 0 0 4px;
}

.react-tel-input .selected-flag:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 4px 0 0 4px;
  border: 1px solid transparent;
  transition:
    box-shadow ease 0.25s,
    border-color ease 0.25s;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #039FFC;
  box-shadow: 0 0 0 2px #9AD9FE;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -8px;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -1px;
  left: 29px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.54);
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 6px solid rgba(0, 0, 0, 0.54);
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 4px;
  margin: 4px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  background-color: #ffffff;
  width: 250px;
  max-height: 220px;
  overflow-y: scroll;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}

.react-tel-input .country-list .flag {
  display: inline-block;
  position: absolute;
  left: 13px;
  top: 4px;
}

.react-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #cccccc;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 6px 9px 7px 46px;
  border: 1px solid transparent;
}

.react-tel-input .country-list .country:not(:last-child) {
  margin-bottom: 2px;
}

.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
}

.react-tel-input .country-list .country:hover {
  background-color: #E6F5FF;
  border: 1px solid #68C5FD;
  border-radius: 4px;
}

.react-tel-input .country-list .country.highlight {
  background-color: #E6F5FF;
  border: 1px solid #68C5FD;
  border-radius: 4px;
}

.react-tel-input .country-list .flag {
  margin-right: 7px;
  margin-top: 6px;
}

.react-tel-input .country-list .country-name {
  margin-right: 6px;
  text-wrap: nowrap;
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 10px 0 6px 10px;
}

.react-tel-input .country-list .search-emoji {
  display: none;
  font-size: 15px;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
}

.react-tel-input .country-list .no-entries-message {
  padding: 7px 10px 11px;
  opacity: 0.7;
}

.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #ffffff;
  padding: 0 5px;
  color: #FE49AD;
}

.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #ffffff;
  padding: 0 5px;
  white-space: nowrap;
}
