@tailwind base;
@tailwind components;
@tailwind utilities;

p:empty {
  display: none;
}

.icon-style {
  @apply rounded-full bg-primary-100 p-2 text-primary-400;
}

.clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply overflow-x-hidden font-body text-black;
  }
  button {
    @apply focus:outline-none;
  }
}

@layer components {
  .outlined-input {
    @apply relative flex rounded border border-[#282F36] border-opacity-30 focus-within:border-opacity-90 hover:border-opacity-90;
  }
  .outlined-input input {
    @apply z-10 block w-full appearance-none bg-transparent px-4 py-4 font-poppins text-base text-[#282F36] focus:outline-none;
  }
  .outlined-input input ~ label {
    @apply absolute left-4 top-0 origin-0 bg-white py-4 font-poppins text-base text-[#282F36] text-opacity-60 duration-300;
  }
  .outlined-input input:disabled {
    @apply text-opacity-50;
  }
  .outlined-input input:focus-within ~ label,
  .outlined-input input:not(:placeholder-shown) ~ label {
    @apply z-0 -ml-1 -translate-y-3 scale-75 transform px-1 py-0;
  }

  .numeric-input {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      /* Firefox */
      input[type=number] {
          -moz-appearance: textfield;
      }
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Native aspect ratio utility classes aren't supported in all browsers yet
  so we'll use a custom one for now. This uses @tailwindcss/aspect-ratio
  under the hood. */
  .aspect-square {
    @apply aspect-h-1 aspect-w-1;
  }
  .aspect-video {
    @apply aspect-h-9 aspect-w-16;
  }
}

/* allows the div to break out of its container and take full width of the screen */
.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  margin-top: 100px;
  left: 50%;
}

.full-width-mobile-only {
  @media only screen and (max-width: 768px) {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    margin-top: 100px;
    left: 50%;
  }
}

.full-width-desktop-only {
  @media only screen and (min-width: 769px) {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    margin-top: 100px;
    left: 50%;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 0.85rem;
  background: #fff;
  padding: 1rem 1.2rem;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
}

/* Radio buttons for use with Tailwind CSS */
.radio-input {
  visibility: hidden;
}
.radio-label {
  position: relative;
  padding-left: 35px;
}
.radio-label:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.radio-border {
  width: 20px;
  height: 20px;
  display: inline-block;
  outline: solid 3px #3c7be2;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.radio-input:checked + .radio-label:after {
  transition: all 0.5s;
  background-color: #3c7be2;
}

/**
 * Skeleton class used to mark elements as "loading".
 */
@keyframes skeleton-animation {
  from {
    background: #ced9e033;
    border-color: #ced9e033;
  }
  to {
    background: #5c708033;
    border-color: #5c708033;
  }
}

/**
 * Skeleton class used to mark elements as "loading".
 */
.skeleton {
  animation: 1s linear infinite alternate skeleton-animation;
  background: #ced9e033;
  background-clip: padding-box !important;
  border-color: #ced9e033 !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none;
}

.skeleton::before,
.skeleton::after,
.skeleton * {
  visibility: hidden !important;
}

#subcategory-boxes-swiper {
  --spacing: 10px;
  --boxes: 3;
  --size: calc((100vw - 24px) / var(--boxes));
}

@media (min-width: 480px) {
  #subcategory-boxes-swiper {
    --spacing: 20px;
    --boxes: 4;
  }
}
@media (min-width: 768px) {
  #subcategory-boxes-swiper {
    --boxes: 6;
  }
}
@media (min-width: 1024px) {
  #subcategory-boxes-swiper {
    --spacing: 30px;
    --boxes: 8;
  }
}
@media (min-width: 1200px) {
  #subcategory-boxes-swiper {
    --spacing: 35px;
    --boxes: 10;
  }
}

#subcategory-boxes-swiper .swiper-slide {
  width: var(--size);
}
#subcategory-boxes-swiper .swiper-slide:not(:last-child) {
  margin-right: var(--spacing);
}
#subcategory-boxes-swiper .swiper-slide-image {
  width: var(--size);
  height: var(--size);
}
#subcategory-boxes-swiper .swiper-button-next,
#subcategory-boxes-swiper .swiper-button-prev {
  top: calc(50% - 18px);
}
#subcategory-boxes-swiper .swiper-button-next::after,
#subcategory-boxes-swiper .swiper-button-prev::after {
  background: #282828;
  color: #fff;
  font-size: 0.85rem;
  padding: 1rem 1.2rem;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
}

/* This resizes and repositions the cookiebot modal to fit the screen better. This was shared by the analytics team. */
div#CybotCookiebotDialog {
  scale: 65%;
  transform: translate(-73%, -50%);
}
@media screen and (min-width: 1280px) {
  div#CybotCookiebotDialog[data-template="bottom"] {
    top: 100%;
    transform: translate(-74%, -100%);
  }
}
